import { Component, OnInit, Input } from '@angular/core';
import { Project } from '../project.model';

@Component({
  selector: 'app-project-card-small',
  templateUrl: './project-card-small.component.html',
  styleUrls: ['./project-card-small.component.css']
})
export class ProjectCardSmallComponent implements OnInit {
	@Input() info: Project
	showDetails: boolean = false;

	constructor() { }

	ngOnInit() {
	}

	toggleDetails() {
		this.showDetails = !this.showDetails;
	}

	closeDetails() {
		this.showDetails = false;
	}

}
