import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.css']
})
export class MainPageComponent implements OnInit {
	animDone: boolean = false
	revealStage = 0; // 1 = logo, 2 = heading, 3 = occupation, 4 = links
	revealOcc = 0;

  constructor() { }

  ngOnInit() {
	setTimeout(() =>
	{
		this.animDone = true
	},5500);

	setTimeout(() =>
	{
		this.revealStage++;
	},6500);
	setTimeout(() =>
	{
		this.revealStage++;
	},7500)
	setTimeout(() =>
	{
		this.revealOcc++;
	}, 7800);
	setTimeout(() =>
	{
		this.revealOcc++;
	}, 8100);
	setTimeout(() =>
	{
		this.revealOcc++;
	}, 8400);
	setTimeout(() =>
	{
		this.revealOcc++;
	}, 8700);
	setTimeout(() =>
	{
		this.revealOcc++;
	}, 9000);
	setTimeout(() =>
	{
		this.revealStage++;
	},9500)
  }

}
