import { Component, OnInit } from '@angular/core';
import { Employment, Education, MetaData, defaultEmployment, defaultMeta, defaultEducation, defaultQualifications } from './resume.models';

@Component({
	selector: 'app-resume-page',
	templateUrl: './resume-page.component.html',
	styleUrls: ['./resume-page.component.css']
})
export class ResumePageComponent implements OnInit {
	employmentHistory = [];
	metaData: MetaData = null;
	educationHistory = [];
	qualifications = [];

	constructor() { }

	ngOnInit() {
		this.employmentHistory = defaultEmployment;
		this.metaData = defaultMeta;
		this.educationHistory = defaultEducation;
		this.qualifications = defaultQualifications;
	}

}
