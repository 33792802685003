import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appPathDraw]'
})
export class PathDrawDirective implements OnChanges{
	@Input() toDraw: "draw" | "undraw" | null;
	length: number;

  	constructor(private el: ElementRef) {
  		this.length = el.nativeElement.getTotalLength() // get length of line drawn
  		el.nativeElement.style.transition = el.nativeElement.style.WebkitTransition = 'none'; //Clear all prev. transitions
  		//Set up the starting position
  		el.nativeElement.style.strokeDasharray = this.length + ' ' + this.length;
  		el.nativeElement.style.strokeDashoffset = this.length;
  		console.log(this.length)
  	}

  	ngOnChanges(changes: SimpleChanges) {
  		if (this.toDraw == "draw") {
  			this.drawPath();
  		}
  		if (this.toDraw == "undraw") {
  			this.undrawPath();
  		}
  	}

  	drawPath(){
  		this.el.nativeElement.getBoundingClientRect();
  		this.el.nativeElement.transition = this.el.nativeElement.style.WebkitTransition = 
  			'stroke-dashoffset 2s ease-in-out';
  		this.el.nativeElement.style.strokeDashoffset = '0';


  	}

  	undrawPath() {
  		this.el.nativeElement.getBoundingClientRect();
  		this.el.nativeElement.transition = this.el.nativeElement.style.WebkitTransition = 
  			'stroke-dashoffset 2s ease-in-out';
  		this.el.nativeElement.style.strokeDashoffset = this.length;
  	}

}
