import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-link-component',
  templateUrl: './link-component.component.html',
  styleUrls: ['./link-component.component.css']
})
export class LinkComponentComponent implements OnInit {
	@Input() name : string;
  constructor() { }

  ngOnInit() {
  }

}
