import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { Link } from '../../shared/link.model';

@Directive({
  selector: '[appLinkColor]'
})

export class LinkColorDirective implements OnInit {
	@Input() linkinfo: Link;

  	constructor(private el: ElementRef) { }

  	ngOnInit() {
  		this.el.nativeElement.style.backgroundColor=this.linkinfo.color; // get length of line drawn
  	}


}
