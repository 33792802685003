import { Component, OnInit, Input } from '@angular/core';
import { RouterOutlet } from '@angular/router';
// import {
// 	trigger,
// 	state,
// 	style,
// 	animate,
// 	transition
// } from '@angular/animations';

@Component({
  selector: 'app-logo-animated',
  templateUrl: './logo-animated.component.html',
  styleUrls: ['./logo-animated.component.css']/*,
  animations: [
   	trigger('openClose', [ 
	  	state('open', style({
	  		'stroke-dasharray':3000,
	  		'stroke-dashoffset':3000
	  	})),
	  	state('closed', style({
	  		'stroke-dashoffset':1000
	  	})),
	  	transition('open => closed', [
	  		animate('3s ease')]),
	  	transition('closed => open', [
	  		animate('3s ease')])
	])
  ]*/
})
export class LogoAnimatedComponent implements OnInit {
  	toDraw = null
  	animDone: boolean = false;
  	constructor() { }

  	ngOnInit() {


  		setTimeout(() =>
  		{
  			this.toDraw = "draw";
  		},1000)

  		setTimeout(() =>
  		{
  			this.toDraw = "undraw";
  		},3500)
  		setTimeout(() =>
  		{
  			this.animDone = true
  		},5500)

  	}

  	prepareRoute(outlet: RouterOutlet) {
  		return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  	}

}
