import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-skill-seperator',
  templateUrl: './skill-seperator.component.html',
  styleUrls: ['./skill-seperator.component.css']
})
export class SkillSeperatorComponent implements OnInit {
	@Input() sectionTitle: string

  constructor() { }

  ngOnInit() {
  }

}
