export class Employment
{
	constructor(public title: string,
				public company: string,
				public companyLocation: string,
				public companyLink: string,
				public dateStart: string,
				public dateEnd: string,
				public responsibilities: string[]) {}
}

export class Education
{
	constructor(public title: string,
				public institution: string,
				public dateReceived: string,
				public grading: string) {}
}

export class MetaData
{
	constructor(public name: string,
				public email: string,
				public location: string,
				public phone : string) {}
}

export const defaultQualifications = [
	"Graduated with a 4-year Bachelor of Engineering in Software Engineering with 4 years of industry experience",
	"Recipient of several awards during university including the President's List, Canada 150 Award and the Ontario Society of Professional Engineering Award",
	"Incoming employee at Aurora Innovation, a major player in the self-driving vehicle space",
	"Developed Embedded software and hardware test setups at Uber ATG's autonomous vehicles",
	"Developed software for real-time video analytics and hardware testing at WOLF",
	"Designed and automated many processes at wolf to streamline testing, validation and production of COTS hardware at WOLF",
	"Started and led several research organizations on- and off-campus including a university robotics team and an Alphapilot automated drone racing team",
	"Experience using Git and Phabricator for version control"
]

export const defaultMeta = new MetaData("Sunny Patel", "sunnypatel1@live.com", "Pittsburgh, PA", "412 726 3044")
export const defaultEducation = [
	new Education(
		"Bachelor of Engineering in Software Engineering",
		"Ontario Tech University (formerly UOIT)",
		"Received May 2020",
		"3.87 GPA")
]

export const defaultEmployment = [
	new Employment(
		"Autonomy Engineer I",
		"Uber ATG (Self Driving Unit)",
		"Pittsburgh, PA. USA",
		"https://www.uber.com/us/en/atg/",
		"August 2020",
		"January 2021",
		[	"Developed a standard platform to run Hardware in the Loop (HIL) integration tests using a Xilinx Zynq 7000 based custom-board",
			"Extended hardware and communications libraries for general use within the company",
			"Developed tools to integrate HIL test results within existing data channels to better aggregate and visualize data regarding failures"
		]

	),

	new Employment(
		"Software Engineer",
		"Wolf Advanced Technology",
		"Stouffville, ON. Canada",
		"https://wolf.ca",
		"August 2019",
		"August 2020",
		[	"Developed an ARM-based HIL control board and firmware/software tooling for generalized automation of functional and environmental testing",
			"Developed procedures to automatically characterize new ASICs for video processing",
			"Developed a network-accessible configuration/control server for automated testing efforts",
			"Expanded automated testing efforts by creating utilities and APIs to manipulate and monitor thermal chambers and vibe tables for long-duration cyclic testing"
		]

	),

	new Employment(
		"Embedded Software Engineering Intern",
		"Uber ATG",
		"Pittsburgh, PA. USA",
		"https://www.uber.com/us/en/atg/",
		"May 2019",
		"August 2020",
		[	"Expanded automated hardware herald tests to ensure new embedded system feature activations were up to spec",
			"Developed and supported adoption of standardized communication/serialization protocols between on-vehicle embedded modules",
			"Created hardware-in-the-loop tests for new releases to automatically validate future firmware releases do not compromise the module"
		]

	),

	new Employment(
		"Junior Software Engineer",
		"Wolf Advanced Technology",
		"Stouffville, ON. Canada",
		"https://wolf.ca",
		"September 2018",
		"May 2019",
		[	"Designed new GPU characterization procedure to verify capabilities of hardware components, created software to run these automatically",
			"Built software to automate the validation of hardware components to streamline and standardize production workflows",
			"Created utilities to streamline testing and setup of multi-stream video encoding/decoding setups making use of several hardware-acceleration setups including Nvidia/AMD GPUs and ASICs",
		]

	),

	new Employment(
		"Software Engineering Intern",
		"Wolf Advanced Technology",
		"Stouffville, ON. Canada",
		"https://wolf.ca",
		"May 2018",
		"September 2018",
		[	"Ran a successful pilot-project to develop graphical software and high-level APIs to accompany hardware projects including video capture, video encode/decode and accelerated video processing",
			"Created standardized self-test software to run on custom rugged hardware for defense and aerospace applications",
			"Streamlined inventory tracking processes for better long-term scalability",
			"Worked with large aerospace companies to address software problems during system integration"
		]

	),

	new Employment(
		"StudentWorks Employee - Web and Digital Media",
		"University of Ontario, Institute of Technology",
		"Oshawa, ON. Canada",
		"https://uoit.ca",
		"May 2017",
		"May 2018",
		[	"Used a web crawling library to find accessibility defects on the University website",
			"Reduced dead links on University website by over 99% by using web crawling and comparing to CMS entries",
			"Helped fix website bugs reported by users"
		]

	),






];