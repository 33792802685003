import { Component, OnInit, Input } from '@angular/core';
import { SkillBadge } from '../../shared/skillbadge.model';

@Component({
  selector: 'app-skill-badge',
  templateUrl: './skill-badge.component.html',
  styleUrls: ['./skill-badge.component.css']
})
export class SkillBadgeComponent implements OnInit {
	@Input() info: SkillBadge;
	//public url: string = "../../../assets/angular-purple.png";
	public url: string = "https://angular.io/assets/images/logos/angular/angular_solidBlack.svg";
	public title: string = "Angular"


  	constructor() { }

  	ngOnInit() {
  	}

}
