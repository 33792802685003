import { Component, OnInit } from '@angular/core';
import { Link } from '../shared/link.model';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {
	links = [
		new Link("/home", "Home", "black"),
		new Link("/skills", "Skills", "purple"),
		new Link("/projects", "Projects", "orange"),
		new Link("/resume","Resume", "gray"),
		new Link("/contact", "Contact", "blue")
	]



  	constructor() {
  		console.log(this.links[1])
  	}

  	ngOnInit() {
  	}

}
