import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';

import { AppComponent } from './app.component';
import { MainPageComponent } from './main-page/main-page.component';
import { LogoAnimatedComponent } from './main-page/logo-animated/logo-animated.component';
import { NavComponent } from './nav/nav.component';
import { NavLinkComponent } from './nav/nav-link/nav-link.component';
import { SkillsComponent } from './skills/skills.component';
import { PathDrawDirective } from './main-page/logo-animated/path-draw.directive';
import { LinkComponentComponent } from './main-page/link-component/link-component.component';
import { SkillsPageComponent } from './skills-page/skills-page.component';
import { ProjectsPageComponent } from './projects-page/projects-page.component';
import { ContactPageComponent } from './contact-page/contact-page.component';
import { ResumePageComponent } from './resume-page/resume-page.component';
import { Link } from './shared/link.model';
import { LinkColorDirective } from './nav/nav-link/link-color.directive';
import { SkillBadgeComponent } from './skills-page/skill-badge/skill-badge.component';
import { SkillSeperatorComponent } from './skills-page/skill-seperator/skill-seperator.component';
import { ProjectCardComponent } from './projects-page/project-card/project-card.component';
import { WebPanelComponent } from './skills-page/web-panel/web-panel.component';
import { ProjectCardSmallComponent } from './projects-page/project-card-small/project-card-small.component';

const appRoutes: Routes = [
	{ path: 'home', component: MainPageComponent, data: {animation: 'HomePage'} },
	{ path: '', component: MainPageComponent, data: {animation: 'DefaultPage'} },
	{ path: 'skills', component: SkillsPageComponent, data: {animation: 'SkillsPage'} },
	{ path: 'projects', component: ProjectsPageComponent, data: {animation: 'ProjectsPage'} },
	{ path: 'resume', component:ResumePageComponent, data: {animation: 'ResumePage'} },
	{ path: 'contact', component:ContactPageComponent, data: {animation: 'ContactPage'}  }
];

@NgModule({
  declarations: [
    AppComponent,
    MainPageComponent,
    LogoAnimatedComponent,
    NavComponent,
    NavLinkComponent,
    SkillsComponent,
    PathDrawDirective,
    LinkComponentComponent,
    SkillsPageComponent,
    ProjectsPageComponent,
    ContactPageComponent,
    ResumePageComponent,
    LinkColorDirective,
    SkillBadgeComponent,
    SkillSeperatorComponent,
    ProjectCardComponent,
    WebPanelComponent,
    ProjectCardSmallComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes)
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
