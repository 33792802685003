import {trigger, animate, style, group, animateChild, query, stagger, transition} from '@angular/animations';

const scrollDown = [
  style({ position: 'relative' }),
  query(':enter, :leave', [
    style({
      position: 'absolute',
      "margin-top": 0,
      left: 0,
      width: '100%',
      height: '100%'
    })
  ]),
  query(':enter', [
    style({ "margin-top": '100%'})
  ]),
  query(':leave', animateChild()),
  group([
    query(':leave', [
      animate('200ms ease-out', style({ "margin-top": '-100%'}))
    ]),
    query(':enter', [
      animate('300ms ease-out', style({ "margin-top": '0%'}))
    ])
  ]),
  query(':enter', animateChild()),
]

const scrollUp = [
  style({ position: 'relative' }),
  query(':enter, :leave', [
    style({
      position: 'absolute',
      "margin-top": 0,
      left: 0,
      width: '100%',
      height: '100%'
    })
  ]),
  query(':enter', [
    style({ "margin-top": '-100%'})
  ]),
  query(':leave', animateChild()),
  group([
    query(':leave', [
      animate('200ms ease-out', style({ "margin-top": '100%'}))
    ]),
    query(':enter', [
      animate('300ms ease-out', style({ "margin-top": '0%'}))
    ])
  ]),
  query(':enter', animateChild()),
]


export const slideInAnimation =
  trigger('routeAnimations', [
    transition('HomePage => *', scrollDown),
    transition('SkillsPage => HomePage', scrollUp),
    transition('SkillsPage => ProjectsPage', scrollDown),
    transition('SkillsPage => ResumePage', scrollDown),
    transition('SkillsPage => ContactPage', scrollDown),

    transition('ProjectsPage => HomePage', scrollUp),
    transition('ProjectsPage => SkillsPage', scrollUp),
    transition('ProjectsPage => ResumePage', scrollDown),
    transition('ProjectsPage => ContactPage', scrollDown),

    transition('ResumePage => HomePage', scrollUp),
    transition('ResumePage => SkillsPage', scrollUp),
    transition('ResumePage => ProjectsPage', scrollUp),
    transition('ResumePage => ContactPage', scrollDown),

    transition('ContactPage => *', scrollUp)

  ]);