import { Component, OnInit } from '@angular/core';
import { SkillBadge } from '../shared/skillbadge.model';

@Component({
  selector: 'app-skills-page',
  templateUrl: './skills-page.component.html',
  styleUrls: ['./skills-page.component.css']
})
export class SkillsPageComponent implements OnInit {
	webSkills: SkillBadge[] = [
		new SkillBadge( "https://angular.io/assets/images/logos/angular/angular_solidBlack.svg",
		 				"Angular"),
		new SkillBadge( "https://angular.io/assets/images/logos/angular/angular_solidBlack.svg",
		 				"NodeJS"),
		new SkillBadge( "https://angular.io/assets/images/logos/angular/angular_solidBlack.svg",
		 				"MongoDB"),
		new SkillBadge( "https://angular.io/assets/images/logos/angular/angular_solidBlack.svg",
		 				"SQL"),
		new SkillBadge( "https://angular.io/assets/images/logos/angular/angular_solidBlack.svg",
		 				"PHP"),
		new SkillBadge( "https://angular.io/assets/images/logos/angular/angular_solidBlack.svg",
		 				".NET")
	]

	roboSkills: SkillBadge[] = [
		new SkillBadge( "https://angular.io/assets/images/logos/angular/angular_solidBlack.svg",
		 				"ROS"),
		new SkillBadge( "https://angular.io/assets/images/logos/angular/angular_solidBlack.svg",
		 				"OpenCV"),
		new SkillBadge( "https://angular.io/assets/images/logos/angular/angular_solidBlack.svg",
		 				"GPS"),
		new SkillBadge( "https://angular.io/assets/images/logos/angular/angular_solidBlack.svg",
		 				"Arduino")
	]

	mvSkills: SkillBadge[] = [
		new SkillBadge( "https://angular.io/assets/images/logos/angular/angular_solidBlack.svg",
		 				"Keras"),
		new SkillBadge( "https://angular.io/assets/images/logos/angular/angular_solidBlack.svg",
		 				"Tensorflow"),
		new SkillBadge( "https://angular.io/assets/images/logos/angular/angular_solidBlack.svg",
		 				"PyTorch"),
		new SkillBadge( "https://angular.io/assets/images/logos/angular/angular_solidBlack.svg",
		 				"CNNs"),
		new SkillBadge( "https://angular.io/assets/images/logos/angular/angular_solidBlack.svg",
		 				"Deep Learning")
	]

	embeddedSkills: SkillBadge[] = [
		new SkillBadge( "https://angular.io/assets/images/logos/angular/angular_solidBlack.svg",
		 				"RISC-V"),
		new SkillBadge( "https://angular.io/assets/images/logos/angular/angular_solidBlack.svg",
		 				"NIOS-II"),
		new SkillBadge( "https://angular.io/assets/images/logos/angular/angular_solidBlack.svg",
		 				"Linux"),
		new SkillBadge( "https://angular.io/assets/images/logos/angular/angular_solidBlack.svg",
		 				"RTOS"),
		new SkillBadge( "https://angular.io/assets/images/logos/angular/angular_solidBlack.svg",
		 				"Assembly")
	]

	appSkills: SkillBadge[] = [
		new SkillBadge( "https://angular.io/assets/images/logos/angular/angular_solidBlack.svg",
		 				"Android"),
		new SkillBadge( "https://angular.io/assets/images/logos/angular/angular_solidBlack.svg",
		 				"Windows")
	]


  constructor() { }

  ngOnInit() {
  }

}
